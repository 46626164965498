.header{
    padding-top: 36px;
    @media only screen and (max-height:840px){
        padding-top: 20px;
    }
    .logContent{
        a{
            color: $colorwhite;
            font-size: 21px;
            @include helveticaneue-ltBold();
            display: block;
            max-width: 230px;
            line-height: 28px;
            text-decoration: none;
        }
    }
    .menuList{
        display: flex;
    }
.menuContent{
    display: flex;
    align-items: center;
    height: 100%;

        .menuList{
            display: flex;
            align-items: center;
            li{
                min-width: 210px;
                padding: 0 20px;
                @media only screen and (min-width:1100px) and (max-width:1447px){
                    min-width: 147px;
                }
               
                @media only screen and (min-width:1100px) and (max-width:1199px){
                    min-width: 160px;
                }
                @media only screen and (min-width:1025px) and (max-width:1099px){
                    min-width: 149px;
                }
                @media only screen and (min-width:991px) and (max-width:1024px){
                    min-width: 145px;
                }
                &.hivermenu{
                    @media only screen and (min-width:1199px) and (max-width:1486px){
                        min-width: 130px;
                    }   
                }
                &.eventmenu{
                    @media only screen and (min-width:1448px) and (max-width:1557px){
                        min-width: 305px;
                    }
                    @media only screen and (min-width:1199px) and (max-width:1447px){
                        min-width: 270px;
                    }    
                }
            }
            a{
                position: relative;
                color: $colorwhite;
                font-size: 18px;
                @include helveticaneue-ltThin();
                line-height: 31px;
                text-align: center;
                text-transform: uppercase;
                @media only screen and (min-width:1100px) and (max-width:1447px){
                    font-size: 16px;
                }
                @media only screen and (max-width:1199px) {
                    font-size: 18px;
                    line-height: 23px;
                } 
                @media only screen and (min-width:1025px) and (max-width:1099px){
                    font-size: 15px;
                }
                @media only screen and (min-width:991px) and (max-width:1024px){
                    font-size: 15px;
                }

                &.active{
                span{
                    position: relative;
                    &:before{
                        content:"";
                        position: absolute;
                        left: -20px;
                        bottom: -5px;
                        height: 2px;
                        display: block;
                        width: 50%;
                        background-color:$colorred;
                        }
                    }
                }
              }
        }

}
.headLogoCol{
    @media only screen and (min-width:1100px) and (max-width:1447px){
        width:22%;
    }
}
.headMenuCol{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
    position: relative;
    align-items: center;
    @media only screen and (max-width:1500px){
        width: 70%;
    }

}
.headerMenuInnerCOl{
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}
.logDetails{
    font-size:22px;
    font-weight: bold;
}
.starImg{
    width: 99px;
    height: 76px;
    position: absolute;
    right:-50px;
    top: 0;
    @media only screen and (min-width:1501px) and (max-width:1685px){
        right: 0;
    }
    @media only screen and (min-width:991px) and (max-width:1099px){
        display:none;
    }
}
.userDetail{
    display: flex;
    align-items: center;
    .userDetailCnt{
        padding-right: 10.5px;
        color: $colorwhite;
        font-size: 15px;
        @include helveticaneue-ltRoman();
    }
    .userEmail {
        text-align: right;
        .userEmailAdd{
            display: block;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 160px;
            white-space: nowrap;
        }
}
.logoutBtnCnt{
    .logoutBtn{
        border: 0;
         .logDetails{
                 &:before{
                     color: $colorred;
                 }
         }
         &:focus, &:active, &.active{
             border: 0;
         }
    }
 }
}
}
