
@mixin helveticaneue-ltThin {
   font-family: 'HelveticaNeueLTStdThin', sans-serif;
    font-weight: 300;
    font-style: normal;
 }
@mixin helveticaneue-ltRoman {
    font-family: 'HelveticaNeueLTStdRoman', sans-serif;
    font-weight: normal;
    font-style: normal;
}
@mixin helveticaneue-ltcn{
    font-family: 'HelveticaNeueLTStdLtCn', sans-serif;
    font-weight: 300;
    font-style: normal;
}
@mixin HelveticaNeue-reg{
    font-family: 'HelveticaNeue_Regular', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@mixin helveticaneue-ltBold {
    font-family: 'HelveticaNeueLTStdBold', sans-serif;
    font-weight: bold;
    font-style: normal;
     font-display: swap;
}
@mixin minionPro-regular {
    font-family: 'MinionPro_Regular', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.css-MuiButtonBase-root-MuiButton-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-weight: 700;
    font-family: Gabarito, tahoma, sans-serif;
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 6px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #9055fd;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    width: 100%;
}