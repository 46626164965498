@font-face {
    font-family: 'HelveticaNeueLTStdThin';
    src: url('./HelveticaNeueLTStd-Lt.woff2') format('woff2'),
        url('./HelveticaNeueLTStd-Lt.woff') format('woff'),
        url('HelveticaNeueLTStd-Lt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueLTStdRoman';
    src: url('./HelveticaNeueLTStd-Roman.woff2') format('woff2'),
        url('./HelveticaNeueLTStd-Roman.woff') format('woff'),
        url('./HelveticaNeueLTStd-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueLTStdLtCn';
    src: url('./HelveticaNeueLTStd-LtCn.woff2') format('woff2'),
        url('./HelveticaNeueLTStd-LtCn.woff') format('woff'),
        url('./HelveticaNeueLTStd-LtCn.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueLTStdBold';
    src: url('./HelveticaNeueLTStd-Bd.woff2') format('woff2'),
        url('./HelveticaNeueLTStd-Bd.woff') format('woff'),
        url('./HelveticaNeueLTStd-Bd.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'HelveticaNeue_Regular';
    src: url('HelveticaNeueRegular.woff2') format('woff2'),
        url('HelveticaNeueRegular.woff') format('woff'),
        url('HelveticaNeueRegular.ttf') format('truetype'),
        url('HelveticaNeueRegular.svg#HelveticaNeueRegular') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'MinionPro_Regular';
    src: url('./MinionPro-Regular.woff2') format('woff2'),
        url('./MinionPro-Regular.woff') format('woff'),
        url('./MinionPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
