.searchFormContainer{
    .form-group{
        padding-bottom: 15px;
        @media only screen and (max-height:840px){
            padding-bottom: 13px;
        }
        .errorMsg{
              @include helveticaneue-ltRoman();
              font-size: 14px;
              line-height: 16px;
              @media only screen and (max-height:840px){
                font-size: 13px;
            }  
        }   
    }
    .customdatePicker{
        position: relative;
        &:before{
            content: "\e902";
            font-family: icomoon !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            right: 0;
            top: 4px;
            width: 40px;
            height: 38px;
            line-height: 30px;
            text-align: center;
            font-size: 30px;
            color: $colorred;
            pointer-events: none;    
            z-index: 1;
        }
        .react-datepicker{
            font-size: 12px;
            @include helveticaneue-ltRoman();
        }
        .react-datepicker__input-container{
            input{
                cursor: pointer;
            }
        }
        .react-datepicker__triangle{
            right:0 !important;
            left: auto !important;
        }
        .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
            font-size: 12px;
            @include helveticaneue-ltRoman();
        }
        .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
            border-radius: 0;
            background-color: rgba($colorred, 0.4);
        }
        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
            background-color: $colorred;
            border-radius: 0;
        }
        .react-datepicker-popper, .css-menu{
            z-index: 2;
        }
    }
    .customSelect{
        position: relative;
        &:before{
            content: "\e904";
            font-family: icomoon !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            right: 0;
            top: 4px;
            width: 40px;
            height: 38px;
            line-height: 30px;
            text-align: center;
            font-size: 18px;
            color: $colorred;
            pointer-events: none;
            z-index: 1;
        }
        div.form-control{
            padding: 0;
            border-radius: 0;
            border: 0;
                div{
                    color: $colorblack; 
                    border: 0;
                    box-shadow: none;
                    text-transform: capitalize;
                    &:focus{
                        box-shadow: none;
                    }    
                }
                &:focus{
                    box-shadow: none;
                } 
            }
            .css-1nmdiq5-menu{
                    z-index: 2;
            }
                .css-1fdsijx-ValueContainer{
                        padding-left: 15px;
                        padding-right: 15px;
                }
    }
    input, select{
        width: 100%;
        border:0;
        border-radius: 0;
        color: $colorblack;
        padding: 7px 15px;
        font-size: 16px;
        @include helveticaneue-ltRoman();
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &::placeholder{
            color: $colorblack;  
        }
       
    }
    .form-control{
        &:focus{
            box-shadow: none;
       }
       
    }
    .submitBtnCol{
    padding-top: 20px;
    padding-bottom: 0;
    @media only screen and (max-height:840px){
        padding-top: 15px;
    }  
    .submitBtn{
        background-color: $colorred;
        font-size: 25px;
        color: $colorwhite;
        border: 0;
        border-radius: 0;
        @include helveticaneue-ltRoman();
        text-transform: uppercase;
        padding: 7px 15px;
        position: relative;
        @media only screen and (max-height:840px){
            font-size: 20px;     
        }
        &:focus{
            box-shadow: none;
        }
    }
}
.formSearchIcon{
    position: absolute;
    bottom: -36px;
    left: -13px;
    font-size:37px;
}
[aria-disabled="true"]{
    background-color: hsl(0, 0%, 88%);
    border-radius: 0;
}
}
.RaLogin-card{
    .MuiButton-root{
        background-color: $colorred;
        &:hover, &:active{
            background-color: rgba($colorred, 0.8);
        }
    }
    label{
        &.Mui-focused{
            color:$colorred;
        }
    }

}