.pageDataSec{
    height: calc(100% - 275px);
    padding-top: 2%;
    @media only screen and (max-height:840px){
        padding-top: 20px;
    }
    &.withoutsubHead{
        height: calc(100% - 259px);
    }
    .siteContainer{
        height:100%;
        .row{
            height:100%;
        }
    }
    .sideBarCol{
        width: 31.4333%;
        padding-right: 75px;
        @media only screen and (max-width:1200px){
                padding-right: 50px;
        }
            .sideBarInnerCnt{
                height: 100%;
                max-width: 396px;
            }
    }
    .detailCol{
        width: 68.5667%;
        .detailCntInner{
            padding: 10px 15px;
            background-color: $colorwhite;
            height: 100%;
            .infinite-scroll-component {
                overflow-x: hidden !important;
            }
        }
        .headerrow{
            p{
                margin: 0;
                text-align: center;
                color: $colorblack;
                @include helveticaneue-ltRoman();
                font-size: 16px;
                padding: 5px 0;
            }
        }
        .dateCol{
            flex: 0 0 18%;
        }
        .titleCol{
            flex: 0 0 38.8%;
        }
        .previewCol{
            flex: 0 0 14.2%;
        }
        .downloadCol{
            flex: 0 0 15%;
        }
        .keyCol{
            flex: 0 0 14%;
        }


        .caseCntList{
                padding: 0 7.5px;
                .nodataText{
                    padding-top: 20px;
                    color: $colorblack;
                    @include helveticaneue-ltRoman();
                    font-size: 16px;
                }
        }
    }
    .donwloadLink{
        color: initial;
        text-decoration: none;
        background: transparent;
        border: 0;
        padding:0;
    }
    .downloadfileIcon{
        font-size:27px;
    }
    .keyIcon{
        font-size:35px;
    }
    .previewCol{
        img{
            max-width: 57px;
        }
    }
    .headerrow{
        .col{
            padding-left: 12px;
            padding-right: 12px;
        }
    }
    .eachColCnt{
        margin-bottom: 10px;
        .eachCol{
            padding-left: 12px;
            padding-right: 12px;
            min-height: 57px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: column;
        }
      h2 {
        @include helveticaneue-ltRoman();
        font-size: 20px;
        color: #c10b25;
      }
      h3 {
        @include helveticaneue-ltRoman();
        font-size: 16px;
      }
        p{
            @include helveticaneue-ltRoman();
            font-size: 16px;
            line-height: 1.1;
            margin:0;
            padding-top: 5px;
          &.smalltext {
            font-size: 12px;
          }
        }
        .previewCol p, .downloadCol p, .keyCol p{
            text-align: center;
            display: block;
            width: 100%;
        }
        .eachMeidadisplayBtn{
            background: transparent;
            border: 0;
            text-decoration: none;
            &.fileUploadBtn{
                cursor: default;
            }
            &:focus, &:active{
                box-shadow: none;
            }
        }
        .mediaPlayIcon{
            font-size: 30px;
        }
        .imgShowIcon, .fileIcon{
            font-size: 30px;
        }
     }
    .caseDetailPage{
        .caseCntList{
            max-width: 800px;
            margin: 0 auto;
            .typeCol{
                flex: 0 0 15.5%;
            }
            .descCol{
                flex: 0 0 62%;
            }
            .downloadCol{
                flex: 0 0 22.5%;
            }
            .nodataText{
                padding-top: 20px;
                color: $colorblack;
                @include helveticaneue-ltRoman();
                font-size: 16px;
            }
        }
        .caseCntListCol{
            width: 90%;
        }
        .medisListCol{
            width: 41.533333%;
        }
    }
    .fileHeaderList{
        border-bottom: 1px solid $colorred;
        margin-bottom: 10px;
        padding-bottom: 5px;
        p{
            @include helveticaneue-ltRoman();
             font-size: 16px;
             margin:0;
        }
    }
}
.breadCrumbList{
    display: flex;
    flex-wrap: wrap;
    padding-top:10px;
        li{
            padding-left: 25px;
            position: relative;
            a, span{
                color: $colorblack;
                font-size: 14px;
                @include helveticaneue-ltBold();
            }
            &:before{
                color:$colorblack;
                font-size: 17px;
                position: absolute;
                left:10px;
                top:0;
                content: '>';
                display: block;
                @include helveticaneue-ltBold();
            }
            &:first-child{
                padding-left: 0;
                &:before{
                    display: none;
            }
            }
            &:last-child{
                padding-right: 0;
            }
        }

}
.pageLink{
    text-decoration: none;
    color: $colorblack;
}
.forumValidationCnt{
    background-color: $colorwhite;
    padding:30px;

    .headerrow{
        p{
            margin: 0;
            color: $colorblack;
            @include helveticaneue-ltBold();
            font-size: 16px;
            padding: 5px 0;
        }
    }
        .row{
            height: auto !important;
        }
        .forumValidationCntInner{
            max-width: 1084px;
            margin-left: auto;
            margin-right: auto;
        }
        .personlisedModelCol{
            flex: 0 0 30%;
        }
        .previewCol{
            flex: 0 0 17%;
        }
        .downloadCol{
            flex: 0 0 17%;
        }
        .validatorCol{
            flex: 0 0 13%;
            justify-content: center;
            align-items: center;
            display: flex;
        }
        .modifiedCol{
            flex: 0 0 13%;
            justify-content: center;
            align-items: center;
            display: flex;
        }

}
.farmForumPage{
        .siteContainer{
            max-width: 1495px;
        }
        .forumPageInner{
            background-color: $colorwhite;
            padding: 30px;
            .fram_title{
                @include helveticaneue-ltRoman();
                font-size: 16px;
                color: $colorblack;
                padding-bottom: 30px;
            }
            .eachFarmItemLink{
                text-decoration: none;
                background: transparent;
                border: 0;
                padding: 0;
                  &.disbaleBtn{
                    opacity: 0.3;
                    pointer-events: none;
                  }
                  &.yourEleMenu{
                    cursor: pointer;
                  }

            }
            .folderIcon{
                font-size: 113px;
            }
            .eachFarmItem{
                    padding-bottom: 80px;
                    span{
                        display: block;
                        .folderIcon:before{
                            color: $colorblack;
                        }
                    }
            }
            .nodataText{
                padding-top: 20px;
                color: $colorblack;
                @include helveticaneue-ltRoman();
                font-size: 16px;
                text-align: center;
            }
        }
        .farmForumHead{
              @include helveticaneue-ltBold();
              font-size: 16px;
              color:$colorblack;
        }
        .sideNavBarCol{
                .editionHeading{
                    color: $colorblack;
                    font-size: 16px;
                    @include helveticaneue-ltBold();
                    text-transform: uppercase;
                }
                .editionItemTxt{
                    color: $colorblack;
                    font-size: 16px;
                    @include helveticaneue-ltBold();
                    text-decoration: none;
                    padding-left: 13px;
                    margin-bottom: 15px;
                    display: block;
                    position: relative;
                    background: transparent;
                    border: 0;
                    &.active{
                        color: $colorred;
                    }
                  .arrowIcon{
                    position: absolute;
                    left: 0;
                    top: 4px;
                  }
              }
        }
        .forumDetailCnt{
            max-width: 1231px;
            margin: 0 auto;
            display: flex;
            .row{
                height:auto;
            }
            .listCnt{
                width: 100%;
            }
            .prevCnt{
                max-width: 235px;
            }
            .headerrow{
                p{
                    margin: 0;
                    text-align: center;
                    color: $colorblack;
                    @include helveticaneue-ltRoman();
                    font-size: 16px;
                    padding: 5px 0;
                }
            }
            .dateCol{
                p{
                    text-align: left;
                }
            }
        }
}
.elementsForm{
    max-width: 1020px;
    margin: 0 auto;
    .row{
        height: auto !important;
    }

    .labelCol, .importCol, .smalllabelCol {
            p{
              font-size: 16px;
              @include helveticaneue-ltRoman();
              color: $colorblack;

              i {
                font-size: 12px;
              }
            }
    }
    .headerrow{
        .labelCol, .importCol{
            p{
                @include helveticaneue-ltBold();
            }
        }

    }
    &.noForm{
        .headerrow{
            display: none;
        }
        .uploadFilecol{
            margin-top:0;
            padding-top:0;
            border-top: 0;
        }
    }
    .eachCol{
        min-height: auto !important;
    }
    .custominputFile::-webkit-file-upload-button{
        visibility: hidden;
    }


      .emailFileCol{
            margin-bottom: 30px;
            .emailUploadCol{
                display: flex;
                input{
                    border: 1px solid rgba($colorblack, 0.5) !important;
                    &:focus{
                        box-shadow: none;
                    }
                }
            }
            .emailLabelCol{
                flex: 0 0 20%;
            }
      }
      .commandeLabel{
            padding-bottom: 30px;
            flex: 0 0 100% !important;
      }
      .commandeForm{
        flex: 0 0 50% !important;
        &.commandeFormBig{
            flex: 0 0 80% !important;
        }
        .customSelect{
            position: relative;
            &:before{
                content: "\e904";
                font-family: icomoon !important;
                speak: none;
                font-style: normal;
                font-weight: 400;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                right: 0;
                top: 4px;
                width: 40px;
                height: 38px;
                line-height: 30px;
                text-align: center;
                font-size: 18px;
                color: $colorred;
                pointer-events: none;
                z-index: 1;
            }
            .elementinput{
                padding-right: 25px;
                padding-left:   5px;
                font-size: 15px;
            }
        }
      }
      .colPadd{
        padding-bottom: 30px;
        &.reduePadd{
            padding-bottom: 20px;
            &.height60 {
              height: 60px !important;
              .smalllabelCol {
                line-height: 1;
              }
            }
        }
    }
      .elementinput{
        border:1px solid rgba($colorblack, 0.5);
        color: $colorblack;
        font-size: 16px;
        @include helveticaneue-ltRoman();
            &:focus{
                box-shadow: none;
            }
      }
      .headerrow{
        padding-bottom:30px;
            p{
                margin: 0;
            }
      }
      .labelCol{
        flex: 0 0 50%;
      }
      .guZdik{
        border-color: $colorred;
            path{
                fill: $colorred;
            }
            span{
                color: $colorblack;
                @include helveticaneue-ltRoman();
            }
      }
     .importInnerCol{
        display: flex;
        align-items: center;
     }
     .excelFieldInfo{
        padding-left: 15px;
     }
     .flexInput{
            display: flex;
            .lessThanValinput{
                width: 35px;
                padding: 2px;
                text-align: center;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border:1px solid rgba($colorblack, 0.5);
                color: $colorblack;
                font-size: 16px;
                border-right:0;
                @include helveticaneue-ltRoman();
                    &:focus{
                        box-shadow: none;
                    }
                &:focus{
                    box-shadow: none;
                }
            }
            .quantityField{
                border-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                padding-left: 0;
        }
     }
}
.fileUploadCol{
    display: flex;
    .uploadListCol{
        padding-left: 15px;
        display: flex;
        align-items: center;
        .fileUploadCol{
                display: flex;
                align-items: center;
                flex-wrap: wrap;

        }
        .filesList{
                display: flex;
                flex-wrap: wrap;
                color: $colorblack;
                font-size: 13px;
                @include helveticaneue-ltRoman();
                .filesRemove{
                    border:0;
                    color: $colorwhite;
                    border-radius: 50%;
                    background-color: $colorred;
                    @include helveticaneue-ltBold();
                    position: relative;
                    width: 20px;
                    height: 20px;
                    margin-left:5px;
                    .closeIcon{
                            font-size: 20px;
                            line-height: 20px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            position: absolute;
                            &:before{
                                color: $colorwhite;
                            }
                    }
                }
            li{
                padding: 0 5px 3px 0;
                display: flex;
                align-items: center;
                padding-right: 2px;
            }
        }
    }
    &.downldFileUpload{
        margin-bottom: 10px;
    }

 }
 .custominputFile::before {
    content: "\e909";
    font-family: icomoon !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 20pt;
    display: none;
  }
  .uploadFilecol{
    margin-bottom:30px;
        .iconDownloadAdd{
            position: relative;
            .upoadloadIcon {
                    position: absolute;
                    left: 0;
                    top:-5px;
                    font-size:30px;
                    color: $colorblack;
                    cursor: pointer;

            }
        }
  }
#previewModalCenter, #videoModalCenter{
        .modal-title{
            @include helveticaneue-ltRoman();
            font-size: 16px;
        }
        .modal-dialog{
            max-width: none;
            .modal-content {
                max-width: 1200px;
                padding: 0 15px;
            }
        }
        .modal-close{
            margin-left: auto;
            background: transparent;
            border: 0;
            font-size:50px;
            padding: 5px;
            line-height: 2.20px;
            color: $colorred;
         }
        .previeMoaltitle{
            @include helveticaneue-ltRoman();
            font-size: 16px;
            color: $colorwhite;
        }
        .modal-body{
            padding: 15px;
            .modalImageCnt{
                img{
                    display: block;
                    margin: 0 auto;
                }
            }
        }
        .modalImgCnt{
            img{
                display: block;
                left: 0;
                margin: 0 auto;
                max-height: 100%;
                max-width: 100%;

            }
        }
}
#videoModalCenter{
        .mediaPlayer{
            background-color: $colorblack;
        }
        video, iframe{
            width: 100%;
            height: 100%;
        }
        .modal-dialog-scrollable .modal-body{
            overflow-y: hidden;
        }
}
#suggestedModalCenter, #modifierModalCenter{
    .modal-dialog{
        max-width: 800px;
    }
    .form-group{
        margin-bottom:20px;
    }
    input, textarea{
        border: 1px solid $colorblack;
        padding: 10px;
        width: 100%;
        @include helveticaneue-ltRoman();
        font-size: 16px;
        border-radius:0;
        &:focus{
            box-shadow: none;
        }
    }
    textarea{
        height: 250px;
        resize:none;
    }
    .suggestFormContainer, .modifierFormContainer{
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
    }

    .suggestModaltitle, .modifierModaltitle{
        @include helveticaneue-ltBold();
        font-size: 16px;
    }
    .modifierHeading{
            @include helveticaneue-ltBold();
            text-align: center;
            font-size: 16px;
    }
    .modal-close{
            margin-left: auto;
            color: $colorred;
            background-color: transparent;
            border: 0;
            font-size: 25px;
            line-height: 25px;
            @include helveticaneue-ltBold();
    }
    .custominputFile{
        border: 0;
        box-shadow: none;
        font-size: 0;
        width:30px;
        height:30px;
        padding: 0;
        &:focus{
            box-shadow: none;
        }
    }
    .upoadloadIcon{
        background-color: $colorwhite;
        line-height: 35px;
    }
    .submitBtnCol{
        button{
            background-color: $colorred;
            font-size: 25px;
            color: #fff;
            border: 0;
            border-radius: 0;
            @include helveticaneue-ltRoman();
            text-transform: uppercase;
            padding: 7px 15px;
            position: relative;
        }

    }

}
.mentionsDataSec{
    height: auto;
    min-height: calc(100% - 275px);
.metionLeagesPage{
    background-color: $colorwhite;
    padding: 20px 20px;
    .text-container {
        text-align: center;
        max-width: 992px;
        min-height: 55vh;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        h1 {
            margin-bottom: 1.5rem;
        }
    }
        p{
            @include helveticaneue-ltRoman();
            font-size: 16px;
            padding-bottom: 15px;
            margin: 0;
            line-height: 1.1;
            &.agency {
                color:$colorred;
            }
        }
        :last-child{
            padding-bottom: 0;
        }
}
}
.errorMsg{
    font-size: 12px;
    padding-top: 2px;
    @include helveticaneue-ltRoman();
    color:$colorred;
}
.btn-rect{
    border: 0;
    color: $colorwhite;
    @include helveticaneue-ltBold();
    font-size: 16px;
    border-radius: 0;
    padding: 10px 15px;
    transition: background-color, 0.5s ease;
    text-transform: uppercase;
    &.btn-red{
        background-color: $colorred;
        &:hover, &:focus, &:active{
            color: $colorwhite;
        }
    }
    &.btn-black{
        background-color: $colorblack;
    }
    &:hover, &:focus, &:active{
        color: $colorwhite;
    }
    &.btn-valid{
        pointer-events: none;
    }
    &.btn-padd-reduce{
        padding: 6px 10px;
    }

}
.closeBtn{
    display: flex;
    align-items: center;
    .btn-rect{
        padding: 5px;
        font-size: 14px;
        line-height: 14px;
    }
}
.thumb-vertical{
    background-color: rgba($colorred, 0.8);
    border-radius: 3px;
}
.MuiScopedCssBaseline-root{
    background-color: transparent !important;
}
.custominputFile{
    border: 0;
    box-shadow: none;
    font-size: 0;
    width:30px;
    height:30px;
    padding: 0;
    &:focus{
        box-shadow: none;
    }
}
#agreecheck{
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
#agreecheck +  label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid $colorred;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
#agreecheck + label{
    position: relative;
    font-size: 16px;
    @include helveticaneue-ltRoman();
    color: $colorblack;
}
#agreecheck:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid $colorred;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .adminPageBtn{
        a{
            color: #fff;
            font-size: 14px;
            border: 2px solid $colorred;
            padding: 5px 8px;
            margin-left: 10px;
            border-radius: 5px;
            display: block;
            &:hover, &:focus{
                color: $colorwhite;
            }
        }
  }
  .btn-rect.disabled{
    color: $colorwhite !important;
    opacity: 0.5;
  }
 .formLoading, .downloadSec{
    position: relative;
    padding: 10px;
    &.active{
        &:before{
            position: absolute;
            width: 100%;
            height:100%;
            content:"";
            left:0;
            top:0;
            display: block;
            background-color: rgba(0, 0, 0, 0.3);
            z-index:1;
        }
        .progress{
            opacity: 1;
            z-index: 2;
        }
    }
  .progress{
    position: absolute;
    height: 20px;
    width: 200px;
    border: 5px solid #c10b25;
    border-radius: 15px;
    z-index: 1;
    background: rgba(193, 11, 37, 0.2);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.5s ease;
}
.progress .color{
    position: absolute;
    background-color: #ffffff;
    width: 0px;
    height: 10px;
    border-radius: 15px;
    animation: progres 4s infinite linear;
}
 }
 .modifierFormContainer .custominputFile {
    border:0 !important;
    font-size: 0 !important;
    padding: 0 !important;
    width: 30px !important;
    opacity: 0;
 }
 .marginDivider{
    border-top: 1px solid #ff7d8b;
    padding-top: 30px;
    margin-top: 30px;
    margin-bottom: 0;
}
 .css-1bz6oir{
    background-image: none !important;
 }

@keyframes progres{
    0%{
      width: 0%;
    }
    25%{
        width: 50%;
    }
    50%{
        width: 75%;
    }
    75%{
        width: 85%;
    }
    100%{
        width: 100%;
    }
};
.ScrollbarsCustom-TrackY{
    position: absolute  !important;
    width: 6px  !important;
    right: 2px  !important;
    bottom: 2px  !important;
    top: 2px  !important;
    border-radius: 3px !important;
    .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY{
        background-color: #c10b25cc  !important;
        border-radius: 3px  !important;
        width:100%  !important;
        display: block;
    }
}
.farmForumPage{
    .ScrollbarsCustom-TrackY{
        .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY{
            height: 300px !important;
        }
    }
}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX{
    display: none !important;
}
.startdatePicker .react-datepicker-popper{
        transform: translate(0, 48px) !important;
}
.searchFormContainer .startdatePicker.customdatePicker .react-datepicker__triangle{
    right: auto;
    left: 4px !important;
}
.spinner {
    -webkit-animation: rotation 1s linear infinite;
    -moz-animation: rotation 1s linear infinite;
    -o-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
    border: 6px solid rgba($colorblack, 0.2);
    border-radius: 100%;
    display: block;
    height: 60px;
    left: 50%;
    margin: 10px 0 20px -30px;
    opacity: 1;
    position: relative;
    top: 50%;
    width: 60px;
    z-index: 3
}
.customSelect{
    [class*="-IndicatorsContainer"] span + [class*="-indicatorContainer"] svg{
        display: none;
        fill:$colorred;
    }
    &.multiselect [class*="-IndicatorsContainer"] [class*="-indicatorContainer"] svg{
        display: none;
    }
    [class*="-IndicatorsContainer"] [class*="-indicatorContainer"] svg{
        fill:$colorred;
    }
    [class*="-IndicatorsContainer"] span + [class*="-indicatorContainer"]{
        padding:15px;
    }
    span + [class*="-indicatorContainer"] svg{
        display: none;
        fill:$colorred;
    }
    &.multiselect [class*="-indicatorContainer"] svg{
        display: none;
    }
    [class*="-indicatorContainer"] svg{
        fill:$colorred;
    }
    span + [class*="-indicatorContainer"]{
        padding:15px;
    }
    [class*="-multiValue"]{
        svg{
            display: block !important;
            fill:$colorred;
        }
    }
        .form-control{
                 div  {
                    cursor: pointer;
                    // div{
                    //     &:first-child {
                    //         padding-left: 15px;
                    //         padding-right: 15px;
                    //     }
                    // }
                    &:nth-child(2){
                            span{
                                display: none;  
                            }
                    } 
                    [class*="-multiValue"]{
                        svg{
                            display: block !important;
                            fill: $colorred;
                        }

                    }
                }
        }
}

.spinner:before {
    border: 6px solid transparent;
    border-radius: 100%;
    border-top-color: rgba($colorblack, 0.8);
    content: "";
    display: block;
    height: 60px;
    left: -6px;
    position: absolute;
    top: -6px;
    width: 60px;
}
.RaSidebar-fixed{
        .MuiList-root{
            a{
                &:nth-child(4){
                    margin-bottom: 10px;
                }
                &:nth-child(5){
                    margin-top: 20px;
                }
                &:nth-child(9){
                    margin-bottom: 10px;
                }
                &:nth-child(10){
                    margin-top: 20px;
                }
                &:nth-child(13){
                    margin-bottom: 10px;
                }
                &:nth-child(14){
                    margin-top: 20px;
                }
            }
        }
}
@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(359deg)
    }
}

@-moz-keyframes rotation {
    0% {
        -moz-transform: rotate(0deg)
    }

    to {
        -moz-transform: rotate(359deg)
    }
}

@-o-keyframes rotation {
    0% {
        -o-transform: rotate(0deg)
    }

    to {
        -o-transform: rotate(359deg)
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(359deg)
    }
}
.base-Popper-root li, .MuiPopover-root li, .MuiAutocomplete-input{
    text-transform: capitalize;
}
.datagrid-body thead .RaDatagrid-headerCell[resource="theme"]{
    display: none;
}
.column-validated, .column-hascorrection{
    span{
            display: block;
            text-align: center;
    }

}
.list-page{
.RaList-noResults {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.RaEmpty-message{
    text-align: center;
    opacity: 0.5;
    margin: 0 1em;
    color: inherit;
}
.RaEmpty-icon {
    width: 9em;
    height: 9em;
}
.MuiTypography-paragraph{
    margin: 0;
    font-weight: 800;
    font-family: Gabarito, tahoma, sans-serif;
    font-size: 2.125rem;
    line-height: 1.235;
    margin-bottom: 16px;
}
.MuiTypography-body1{
    margin: 0;
    font-family: Gabarito, tahoma, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
}
.RaEmpty-toolbar {
    text-align: center;
    margin-top: 2em;
}
}
.column-locked{
    svg{
        width:30px;
        height: 30px;
    }
}