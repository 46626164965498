.footer_Sec{
    padding: 50px 0;
    @media only screen and (max-height:840px){
        padding: 25px 0;
    }
    p{
        color: $colorwhite;
        font-size: 12px;
        @include helveticaneue-ltRoman();
        .weblink{
            color: $colorwhite;
        }
    }
}