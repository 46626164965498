html, body{
   @include helveticaneue-ltRoman();
    color:$colorblack;
    min-height: 100vh;
}
body.nolayout{
    font-size: 10px;
}
#root, body, html {
    height: 100%;
}
.caselayout,  .caselayout body, .caselayout #root{
    height: auto;
}
body{
    background-image: url(/images/bg_img.png);
    background-color: grey;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
}
.siteContainer{
    max-width: 1600px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
} 
.resetList{
    margin: 0;
    padding: 0;
    list-style-type:none ;
}
.noMargin{
    margin: 0;
}
.btn-img{
    border: 0;
    padding: 0;
}
.alertTxt{
    @include helveticaneue-ltRoman();
    font-size: 16px !important;
    &.errorMsg{
        color: red;
    }
    &.successMsg{
        color: green;
    }
}
.css-c1bgk0-RaLogin-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    height: 1px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}
.css-c1bgk0-RaLogin-root{
    background-image: none !important;
}
.css-c1bgk0-RaLogin-root .RaLogin-card {
    min-width: 300px;
    margin-top: 6em;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root{
    background-color: #fff;
    color: #544f5a;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 6px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    box-shadow: rgba(144, 85, 253, 0.2) -2px 2px, rgba(144, 85, 253, 0.1) -4px 4px, rgba(144, 85, 253, 0.05) -6px 6px;
    overflow: hidden;
}
.css-c1bgk0-RaLogin-root .RaLogin-avatar {
    margin: 1em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.css-iisrix-MuiAvatar-root {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    font-family: Gabarito, tahoma, sans-serif;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #f0f1f6;
    background-color: #bdbdbd;
}
.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
}
.css-MuiCardContent-root {
    padding: 16px;
}
.css-1tz8rwi-RaLoginForm-root .RaLoginForm-content {
    width: 300px;
}
.last-root-element {
    padding-bottom: 24px !important;
}
.css-1tz8rwi-RaLoginForm-root .MuiInputLabel-root span{
    display: inline-block;
    background: #fff;
}
.css-MuiFormControl-root-MuiTextField-root-RaResettableTextField-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    vertical-align: top;
    margin-top: 8px;
    margin-bottom: 4px;
    width: 100%;
}
.css-MuiButtonBase-root-MuiButton-root{
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-weight: 700;
    font-family: Gabarito, tahoma, sans-serif;
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 6px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #9055fd;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    width: 100%;
}
.css-MuiFormLabel-root-MuiInputLabel-root {
    color: #89868D;
    font-family: Gabarito, tahoma, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: translate(14px, 9px) scale(1);
    -moz-transform: translate(14px, 9px) scale(1);
    -ms-transform: translate(14px, 9px) scale(1);
    transform: translate(14px, 9px) scale(1);
    -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
}
.css-MuiInputBase-root-MuiFilledInput-root {
    font-family: Gabarito, tahoma, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: #544f5a;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border: 1px solid #89868D;
    border-radius: 6px;
}
.css-MuiInputBase-input-MuiFilledInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: #544f5a;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 40px;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding-top: 1px;
    padding: 8.5px 14px;
}
.css-MuiFormHelperText-root {
    color: #89868D;
    font-family: Gabarito, tahoma, sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
}
.css-1mog9k0-MuiFormLabel-root-MuiInputLabel-root {
    color: #89868D;
    font-family: Gabarito, tahoma, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    -webkit-transform: translate(14px, 9px) scale(1);
    -moz-transform: translate(14px, 9px) scale(1);
    -ms-transform: translate(14px, 9px) scale(1);
    transform: translate(14px, 9px) scale(1);
    -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
}